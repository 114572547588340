import {QuizModel} from 'models/QuizModel'
import {RoomModel} from 'models/RoomModel'
import {QuizResult, QuizState, UserState} from 'pages'

type StorageStepState =
	| {key: 'room'; room: string}
	| {key: 'warmup'; room: string}
	| {key: 'question'; room: string; question: number; time_spend: number}
	| {key: 'answer'; room: string; question: number; answer: number | 'timeout'}
	| {key: 'room_finished'; room: string}
	| {key: 'finished'; result: QuizResult}

type StorageState = {user: UserState} & StorageStepState

export const loadQuizStateFromsessionStorage = (
	quiz: QuizModel,
	roomKey: string | undefined
): QuizState => {
	const state: StorageState =
		sessionStorage.state && JSON.parse(sessionStorage.state)
	const user: UserState = state?.user

	if (!state || !user) {
		return {
			key: 'warmup',
			user: {progress: [], rooms: []},
			room: quiz.rooms[0]
		}
	}

	const room = quiz.rooms.find(room => room.key === roomKey) || quiz.rooms[0]
	if (state.key === 'finished') {
		return state
	}

	if (state.key === 'warmup') {
		return {
			key: 'warmup',
			user,
			room
		}
	}

	if (state.key === 'question' && state.room === room.key) {
		const question = quiz.getQuestion(state)
		if (question) {
			return {
				key: 'question',
				question,
				user,
				time_spend: state.time_spend
			}
		}
	}

	if (state.key === 'room' && state.room === room.key) {
		return {
			key: 'room',
			user,
			room
		}
	}

	if (state.key === 'answer' && state.room === room.key) {
		const question = quiz.getQuestion(state)
		const answer = quiz.getAnswer(state)
		if (question) {
			return {
				key: 'answer',
				question,
				user,
				answer: answer || 'timeout'
			}
		}
	}

	if (room.getIsFinished(user)) {
		if (quiz.isFinished(user)) {
			const result: QuizResult = {
				score: quiz.calculateScore(user),
				max_score: quiz.calculateMaxScore(user)
			}
			return {
				key: 'finished',
				user,
				result
			}
		}

		return {
			key: 'room_finished',
			room,
			user
		}
	}

	return {
		key: 'room',
		room,
		user
	}
}

export const storeQuizStateTosessionStorage = (state: QuizState): void => {
	if (state.key === 'loading') {
		saveStep(undefined)
		return
	}

	if (state.key === 'room') {
		saveStep({
			key: 'room',
			room: state.room.key,
			user: state.user
		})
		return
	}

	if (state.key === 'warmup') {
		saveStep({
			key: 'warmup',
			room: state.room.key,
			user: state.user
		})
		return
	}

	if (state.key === 'question') {
		saveStep({
			key: 'question',
			room: state.question.room.key,
			question: state.question.index,
			user: state.user,
			time_spend: state.time_spend
		})
		return
	}

	if (state.key === 'answer') {
		saveStep({
			key: 'answer',
			room: state.question.room.key,
			question: state.question.index,
			answer: typeof state.answer === 'string' ? 'timeout' : state.answer.index,
			user: state.user
		})
		return
	}

	if (state.key === 'room_finished') {
		saveStep({
			key: 'room_finished',
			room: state.room.key,
			user: state.user
		})
		return
	}

	if (state.key === 'finished') {
		saveStep(state)
		return
	}
}

export const saveStep = (step: StorageState | undefined) => {
	sessionStorage.state = JSON.stringify(step)
}
