import {Answer} from 'layout/Answer'
import {Container} from 'layout/Container'
import {Layout} from 'layout/Layout'
import {Question} from 'layout/Question'
import {QuizFinished} from 'layout/QuizFinished'
import {Room} from 'layout/Room'
import {RoomFinished} from 'layout/RoomFinished'
import {Spinner} from 'layout/Spinner'
import {Warmup} from 'layout/Warmup'
import {AnswerModel} from 'models/AnswerModel'
import {QuestionModel} from 'models/QuestionModel'
import {RoomModel} from 'models/RoomModel'
import type {GetStaticProps, NextPage} from 'next'
import React, {useEffect, useState} from 'react'
import {getQuizData} from 'server/getQuizData'
import {
	loadQuizStateFromsessionStorage,
	storeQuizStateTosessionStorage
} from 'store'
import {sessionStorageTest} from 'util/sessionStorageTest'
import {QuizData, QuizModel} from '../models/QuizModel'

export type UserState = {
	progress: QuizAnswer[]
	rooms: number[]
}

export type QuizResult = {
	score: number
	max_score: number
}

export type QuizAnswer = {
	room: string
	question: number
	answer: number | 'timeout'
	time_spend: number
}

export type QuizState =
	| {key: 'loading'}
	| {key: 'room'; user: UserState; room: RoomModel}
	| {key: 'warmup'; user: UserState; room: RoomModel}
	| {
			key: 'question'
			user: UserState
			question: QuestionModel
			time_spend: number
	  }
	| {
			key: 'answer'
			user: UserState
			question: QuestionModel
			answer: AnswerModel | 'timeout'
	  }
	| {key: 'room_finished'; user: UserState; room: RoomModel}
	| {key: 'finished'; user: UserState; result: QuizResult}

const QuizPage: NextPage<{data: QuizData}> = ({data}) => {
	const [quiz] = useState<QuizModel>(new QuizModel(data))
	const [state, setState] = useState<QuizState>({key: 'loading'})
	const [lsEnabled, setLsEnabled] = useState(true)

	const storeState = (newState: QuizState) => {
		storeQuizStateTosessionStorage(newState)
		setState(newState)
	}

	useEffect(() => {
		if (!sessionStorageTest()) {
			setLsEnabled(false)
			return
		}
		if (state.key !== 'loading') {
			setState(
				loadQuizStateFromsessionStorage(
					quiz,
					`vragenreeks${state.user.rooms.slice(-1)[0] + 1}`
				)
			)
			return
		}

		setState(loadQuizStateFromsessionStorage(quiz, `vragenreeks1`))
	}, [quiz, state.key])

	if (!lsEnabled) {
		return (
			<Layout>
				<div style={{fontSize: '18px'}}>
					<Container mod="small">
						<p>
							Het lijkt erop dat je het gebruik van cookies uitgeschakeld hebt.
							De quiz is enkel beschikbaar in browsers waarbij cookies (en
							sessionStorage) actief zijn. Deze zijn immers noodzakelijk om je
							resultaten op te slaan tijdens het quizzen.
						</p>
						<p>
							Open de quiz in een andere browser of wijzig je instellingen en
							ververs de pagina om verder te gaan.
						</p>
					</Container>
				</div>
			</Layout>
		)
	}

	return (
		<Layout>
			<QuizPageContent quiz={quiz} state={state} storeState={storeState} />
		</Layout>
	)
}

export default QuizPage

export const QuizPageContent: React.FC<{
	quiz: QuizModel
	state: QuizState
	storeState: (newState: QuizState) => void
}> = ({quiz, state, storeState}) => {
	if (state.key === 'loading') {
		const user: UserState = {
			progress: [],
			rooms: []
		}

		const room = quiz.rooms[0]

		return <Spinner />
	}

	if (state.key === 'warmup') {
		return (
			<Warmup
				room={state.room}
				user={state.user}
				onFinished={() => {
					const user = state.user
					const room = state.room
					const question = state.room.questions[0]
					if (!question) {
						if (quiz.isFinished(user)) {
							storeState({
								key: 'finished',
								user,
								result: {
									score: quiz.calculateScore(user),
									max_score: quiz.calculateMaxScore(user)
								}
							})
						} else {
							storeState({key: 'room_finished', user, room})
						}
					} else {
						storeState({key: 'question', user, question, time_spend: 0})
					}
				}}
			/>
		)
	}

	if (state.key === 'question') {
		return (
			<Question
				question={state.question}
				initialTimeSpend={state.time_spend}
				onUpdateTimeSpend={timeSpend => {
					storeState({
						key: 'question',
						user: state.user,
						question: state.question,
						time_spend: timeSpend
					})
				}}
				onAnswer={(answer: AnswerModel | 'timeout', time) => {
					const user: UserState = {
						...state.user,
						progress: [
							...state.user.progress,
							{
								room: state.question.room.key,
								answer: typeof answer === 'string' ? answer : answer.index,
								question: state.question.index,
								time_spend: time
							}
						]
					}

					storeState({
						key: 'answer',
						user,
						question: state.question,
						answer: typeof answer === 'string' ? 'timeout' : answer
					})
				}}
			/>
		)
	}

	if (state.key === 'answer') {
		return (
			<Answer
				question={state.question}
				answer={state.answer}
				user={state.user}
				onNext={() => {
					const nextQuestion = state.question.nextQuestion
					if (!nextQuestion) {
						if (state.question.room.quiz.isFinished(state.user)) {
							const user = state.user
							storeState({
								key: 'finished',
								user,
								result: {
									score: quiz.calculateScore(user),
									max_score: quiz.calculateMaxScore(user)
								}
							})
						} else {
							storeState({
								key: 'room_finished',
								user: state.user,
								room: state.question.room
							})
						}
					} else {
						storeState({
							key: 'question',
							user: state.user,
							question: nextQuestion,
							time_spend: 0
						})
					}
				}}
			/>
		)
	}

	if (state.key === 'room_finished') {
		return (
			<RoomFinished
				user={state.user}
				room={state.room}
				onNext={() => {
					const currentRoom = +state.room.key.slice(-1)

					const newRooms = [...state.user.rooms, currentRoom]
					const user = {...state.user, rooms: newRooms}

					storeState({key: 'room', user, room: quiz.rooms[currentRoom]})
				}}
			/>
		)
	}

	if (state.key === 'room') {
		return (
			<Room
				room={state.room}
				onStart={() => {
					const user = state.user
					const room = state.room
					const question = room.getUnAnsweredQuestion(user)
					if (question) {
						storeState({key: 'question', user, question, time_spend: 0})
					} else {
						storeState({key: 'room_finished', user, room})
					}
				}}
			/>
		)
	}

	return <QuizFinished result={state.result} />
}

export const getStaticProps: GetStaticProps<{data: QuizData}> = async () => {
	const quizData = getQuizData()

	return {
		props: {
			data: {
				...quizData
			}
		},
		revalidate: 60 * 5
	}
}
