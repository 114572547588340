import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Layout.module.scss'

const styles = fromModule(css)

export const Layout: React.FC = ({children}) => {
	return (
		<div className={styles.layout()}>
			<div className={styles.layout.content()}>{children}</div>
		</div>
	)
}
