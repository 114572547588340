export function sessionStorageTest() {
	var test = 'test'
	try {
		sessionStorage.setItem(test, test)
		sessionStorage.removeItem(test)
		return true
	} catch (e) {
		return false
	}
}
