import {fromModule} from 'util/styler/Styler'
import css from './Button.module.scss'

const styles = fromModule(css)

export const Button: React.FC<{
	disabled?: boolean
	value: string
	onClick?: () => void
}> = ({disabled, value, onClick}) => {
	return (
		<button
			type={onClick ? 'button' : 'submit'}
			className={styles.button()}
			disabled={disabled}
			onClick={onClick}
		>
			{value}
		</button>
	)
}
