import {Memoize} from 'typescript-memoize'
import {AnswerData, AnswerModel} from './AnswerModel'
import {RoomModel} from './RoomModel'

export type QuestionData = {
	question: string
	time: number
	explanation?: string
	image?: string
	answers: AnswerData[]
}

export class QuestionModel {
	constructor(readonly room: RoomModel, readonly state: QuestionData) {}

	@Memoize()
	get isWarmup() {
		return this.room.warmup === this
	}

	@Memoize()
	get time() {
		return this.state.time
	}

	@Memoize()
	get index() {
		return this.room.questions.findIndex(q => q === this)
	}

	@Memoize()
	get isLast() {
		return this.room.questions.length === this.index + 1
	}

	@Memoize()
	get question() {
		return this.state.question
	}

	@Memoize()
	get correctAnswer(): AnswerModel {
		return this.answers.find(answer => answer.isCorrect)!
	}

	@Memoize()
	get nextQuestion(): QuestionModel | undefined {
		return this.room.questions[this.index + 1]
	}

	@Memoize()
	get explanation() {
		return this.state.explanation
	}

	@Memoize()
	get image() {
		return this.state.image
	}

	@Memoize()
	get answers() {
		return this.state.answers.map(d => new AnswerModel(this, d))
	}
}
