import {AnswerModel} from 'models/AnswerModel'
import {QuestionModel} from 'models/QuestionModel'
import {UserState} from 'pages'
import {Container} from './Container'
import {fromModule} from 'util/styler/Styler'
import css from './Answer.module.scss'
import {Choice} from './components/Choice'

const styles = fromModule(css)

export const Answer: React.FC<{
	question: QuestionModel
	answer: AnswerModel | 'timeout'
	user: UserState
	onNext: () => void
}> = ({question, answer, user, onNext}) => {
	let result
	if (answer === 'timeout') result = 'Buiten de tijd!'
	else if (typeof answer !== 'string' && answer.isCorrect) result = 'Juist!'
	else result = 'Fout!'

	return (
		<>
			<Container mod="large">
				{question.image ? (
					<img
						className={styles.image()}
						src={question.image.replace('/public/', './')}
						alt="Image"
					/>
				) : (
					<div />
				)}
			</Container>
			<Container mod="small">
				<div className={styles.answer()}>
					<div className={styles.answer.number()}>
						{!question.isWarmup
							? `Vraag ${question.index + 1}/${question.room.questions.length}`
							: `Opwarmingsvraag`}
					</div>
					<div className={styles.answer.answer()}>{result}</div>
					<div className={styles.answer.correct()}>
						Correct antwoord:
						<div className={styles.answer.correct.answer()}>
							<Choice
								answer={question.correctAnswer}
								checked={false}
								hideCheckbox={true}
								finished={true}
							/>
						</div>
					</div>
					<p>{question.explanation}</p>
					{!question.isWarmup && (
						<div className={styles.answer.score()}>
							Score: {question.room.calculateScore(user)}/{question.index + 1}
						</div>
					)}
				</div>
			</Container>
			<Container mod="small">
				<button className={styles.answer.button()} onClick={onNext}>
					{question.isWarmup
						? 'Start de quiz'
						: question.isLast
						? 'Beëindig deze reeks'
						: 'Volgende vraag'}
				</button>
			</Container>
		</>
	)
}
