import {fromModule} from 'util/styler/Styler'
import css from './Checkbox.module.scss'

const styles = fromModule(css)

export const Checkbox: React.FC<{
	checked: boolean
}> = ({checked}) => {
	return (
		<div className={styles.checkbox()}>
			<img
				className={styles.checkbox.checkmark.is({checked})()}
				src="./checkmark.png"
			/>
		</div>
	)
}
