import {fromModule} from 'util/styler/Styler'
import css from './Spinner.module.scss'

const styles = fromModule(css)

export const Spinner: React.FC = () => {
	return (
		<div className={styles.loader()}>
			<div className={styles.loader.spinner()}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}
