import {QuizResult} from 'pages'
import {fromModule} from 'util/styler/Styler'
import {Container} from './Container'
import css from './QuizFinished.module.scss'

const styles = fromModule(css)

export const QuizFinished: React.FC<{
	result: QuizResult
}> = ({result}) => {
	return (
		<div className={styles.quiz()}>
			<Container mod="small">
				<div>
					<div className={styles.quiz.score.title()}>Uw totale score:</div>
					<div className={styles.quiz.score.score()}>
						{result?.score}/{result?.max_score}
					</div>
				</div>
				<div className={styles.quiz.text()}>
					<div>Goed gedaan!</div>
				</div>
				<a
					className={styles.quiz.link()}
					href="https://www.sport.vlaanderen/sportclubs/thema-s/sport-en-innovatie/"
					target="_blank"
				>
					Ontdek meer over de toekomst van sport in vlaanderen
				</a>
				<span className={styles.quiz.label()}>Of</span>
				<a
					className={styles.quiz.link()}
					href="mailto:innovatielab@sport.vlaanderen"
				>
					Bij het Sportinnovatielab via innovatielab@sport.vlaanderen
				</a>
			</Container>
		</div>
	)
}
