import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Container.module.scss'

const styles = fromModule(css)

type AsType = {
	mod: 'normal' | 'large' | 'small'
}

export const Container: React.FC<AsType> = ({mod, children}) => (
	<div className={styles.container.mod(mod)()}>{children}</div>
)
