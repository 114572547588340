import {RoomData, RoomModel} from 'models/RoomModel'
import {UserState} from 'pages'
import {Memoize} from 'typescript-memoize'
import {AnswerModel} from './AnswerModel'
import {QuestionData, QuestionModel} from './QuestionModel'

export type QuizData = {
	rooms: RoomData[]
	opwarmingsvraag: QuestionData
}

export const POINTS_PER_CORRECT_ANSWER = 1

export class QuizModel {
	constructor(readonly state: QuizData) {}

	@Memoize()
	get rooms() {
		return this.state.rooms.map(d => new RoomModel(this, d))
	}

	@Memoize()
	get path() {
		return
	}

	@Memoize()
	get maxScore() {
		let maxScore = 0
		this.rooms.forEach(room => {
			maxScore += room.maxScore
		})
		return maxScore
	}

	isFinished(user: UserState) {
		const unfinishedRoom = this.rooms.find(
			room => !!room.getUnAnsweredQuestion(user)
		)
		return !unfinishedRoom
	}

	calculateScore(user: UserState) {
		let score = 0
		this.rooms.forEach(room => {
			score += room.calculateScore(user)
		})
		return score
	}

	calculateMaxScore(user: UserState) {
		let maxScore = 0
		this.rooms.forEach(room => {
			const isPassed = user.progress.find(answer => answer.room === room.key)
			if (!isPassed) return
			maxScore += room.maxScore
		})
		return maxScore
	}

	getRoom(path: {room: string}): RoomModel | undefined {
		return this.rooms.find(room => room.key === path.room)
	}

	getQuestion(path: {
		room: string
		question: number
	}): QuestionModel | undefined {
		return this.getRoom(path)?.questions.find(q => q.index === path.question)
	}

	getAnswer(path: {
		room: string
		question: number
		answer: number | 'timeout'
	}): AnswerModel | undefined {
		return this.getQuestion(path)?.answers.find(a => a.index === path.answer)
	}
}
