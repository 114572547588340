import {AnswerModel} from 'models/AnswerModel'
import {QuestionModel} from 'models/QuestionModel'
import {useEffect, useState} from 'react'
import {fromModule} from 'util/styler/Styler'
import {Button} from './Button'
import {Choice} from './components/Choice'
import {Container} from './Container'
import css from './Question.module.scss'

const styles = fromModule(css)

export const renderTime = (seconds: number): string => {
	const minutes = Math.floor(seconds / 60)
	const displaySeconds = seconds - minutes * 60
	const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`
	const secondsStr =
		displaySeconds < 10 ? `0${displaySeconds}` : `${displaySeconds}`

	return `${minutesStr}:${secondsStr}`
}

export const Question: React.FC<{
	question: QuestionModel
	initialTimeSpend: number
	onUpdateTimeSpend: (timeSpend: number) => void
	onAnswer: (answer: AnswerModel | 'timeout', time: number) => void
}> = ({question, initialTimeSpend, onUpdateTimeSpend, onAnswer}) => {
	const [selectedAnswer, setAnswer] = useState<AnswerModel | null>(null)
	const [seconds, setSeconds] = useState(question.time - initialTimeSpend)
	const timeSpend = question.time - seconds

	useEffect(() => {
		onUpdateTimeSpend(timeSpend)
		const interval = setTimeout(() => {
			if (seconds <= 0) {
				if (selectedAnswer) {
					onAnswer(selectedAnswer, timeSpend)
				} else {
					onAnswer('timeout', timeSpend)
				}
				return
			}
			setSeconds(seconds - 1)
		}, 1000)
		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seconds])

	return (
		<>
			<Container mod="small">
				<div className={styles.timer()}>
					<div className={styles.timer.image()}>
						<img src="./timer.png" alt="Timer" width="14" height="17" />
					</div>
					{renderTime(seconds)}
				</div>
				<div className={styles.question()}>
					<h2>
						{!question.isWarmup
							? `Thema: ${question.room.theme}`
							: 'Opwarmingsvraag'}
					</h2>
					<div className={styles.question()}>
						<div>
							{!question.isWarmup &&
								`Vraag ${question.index + 1}/${question.room.questions.length}`}
						</div>
						<div className={styles.question.question()}>
							{question.question}
						</div>
					</div>
				</div>
			</Container>
			<form
				onSubmit={e => {
					if (selectedAnswer) {
						onAnswer(selectedAnswer, timeSpend)
					}
					e.preventDefault()
				}}
			>
				{question.answers.map((answer, i) => (
					<Container mod={answer.image ? 'large' : 'small'} key={i}>
						<label className={styles.answer()}>
							<input
								className={styles.answer.input()}
								type="radio"
								value={answer.answer}
								checked={answer === selectedAnswer}
								onChange={() => {
									setAnswer(answer)
								}}
							/>
							<Choice answer={answer} checked={answer === selectedAnswer} />
						</label>
					</Container>
				))}
				<Container mod="small">
					<Button disabled={!selectedAnswer} value="Beantwoord" />
				</Container>
			</form>
		</>
	)
}
