import {UserState} from 'pages'
import {Memoize} from 'typescript-memoize'
import {QuestionData, QuestionModel} from './QuestionModel'
import {POINTS_PER_CORRECT_ANSWER, QuizModel} from './QuizModel'

export type RoomData = {
	key: string
	theme: string
	description: string
	questions: QuestionData[]
}

export class RoomModel {
	constructor(readonly quiz: QuizModel, readonly state: RoomData) {}

	@Memoize()
	get warmup(): QuestionModel {
		const data = this.quiz.state.opwarmingsvraag
		return new QuestionModel(this, data)
	}

	@Memoize()
	get key() {
		return this.state.key
	}

	@Memoize()
	get theme() {
		return this.state.theme
	}

	@Memoize()
	get description() {
		return this.state.description
	}

	@Memoize()
	get questions() {
		return this.state.questions.map(d => new QuestionModel(this, d))
	}

	@Memoize()
	get maxScore() {
		return this.questions.length * POINTS_PER_CORRECT_ANSWER
	}

	calculateScore(user: UserState): number {
		let score = 0
		this.questions.forEach(question => {
			const userAnswer = user.progress.find(
				answer => answer.room === this.key && answer.question === question.index
			)
			if (!userAnswer) return
			if (userAnswer.answer === 'timeout') return

			const answerModel = question.answers.find(
				answer => answer.index === userAnswer.answer
			)
			if (!answerModel?.isCorrect) return
			score += POINTS_PER_CORRECT_ANSWER
		})
		return score
	}

	getUnAnsweredQuestion(user: UserState): QuestionModel | undefined {
		return this.questions.find(question => {
			const userAnswer = user.progress.find(
				answer => answer.room === this.key && answer.question === question.index
			)
			return !userAnswer
		})
	}

	getIsFinished(user: UserState): boolean {
		return !this.getUnAnsweredQuestion(user)
	}
}
