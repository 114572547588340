import {RoomModel} from 'models/RoomModel'
import {UserState} from 'pages'
import {Container} from './Container'
import {fromModule} from 'util/styler/Styler'
import css from './RoomFinished.module.scss'
import {Checkbox} from './components/Checkbox'
import {Button} from './Button'

const styles = fromModule(css)

export const RoomFinished: React.FC<{
	room: RoomModel
	user: UserState
	onNext: () => void
}> = ({room, user, onNext}) => {
	return (
		<div className={styles.room()}>
			<Container mod="small">
				<div className={styles.room.score()}>
					<div className={styles.room.score.title()}>Uw totale score:</div>
					<div className={styles.room.score.score()}>
						{room.quiz.calculateScore(user)}/{room.quiz.calculateMaxScore(user)}
					</div>
				</div>
				<Button
					value="Ga naar volgende thema"
					onClick={() => onNext()}
				></Button>
			</Container>
		</div>
	)
}
