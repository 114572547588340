import {AnswerModel} from 'models/AnswerModel'
import {RoomModel} from 'models/RoomModel'
import {UserState} from 'pages'
import {useState} from 'react'
import {Answer} from './Answer'
import {Question} from './Question'

type WarmupState =
	| {key: 'question'}
	| {key: 'answer'; answer: AnswerModel | 'timeout'}

export const Warmup: React.FC<{
	room: RoomModel
	user: UserState
	onFinished: () => void
}> = ({room, user, onFinished}) => {
	const [state, setState] = useState<WarmupState>({key: 'question'})
	const question = room.warmup

	if (state.key === 'question') {
		return (
			<Question
				initialTimeSpend={0}
				question={question}
				onUpdateTimeSpend={() => {}}
				onAnswer={answer => {
					setState({key: 'answer', answer})
				}}
			/>
		)
	}

	return (
		<Answer
			question={question}
			answer={state.answer}
			user={user}
			onNext={onFinished}
		/>
	)
}
