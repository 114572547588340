import {AnswerModel} from 'models/AnswerModel'
import {QuestionModel} from 'models/QuestionModel'
import {UserState} from 'pages'
import {fromModule} from 'util/styler/Styler'
import {Checkbox} from './Checkbox'
import css from './Choice.module.scss'

const styles = fromModule(css)

export const Choice: React.FC<{
	answer: AnswerModel
	checked: boolean
	hideCheckbox?: boolean
	finished?: boolean
}> = ({answer, hideCheckbox, checked, finished}) => {
	if (answer.image) {
		return (
			<div className={styles.choice()}>
				{!hideCheckbox && (
					<div className={styles.choice.box()}>
						<Checkbox checked={checked} />
					</div>
				)}
				<img
					className={styles.choice.image.is({finished})()}
					src={answer.image.replace('/public/', './')}
					alt="Image"
				/>
				<div className={styles.choice.label.is({finished})()}>
					{answer.answer}
				</div>
			</div>
		)
	}

	return (
		<div className={styles.choice()}>
			<div className={styles.choice.text.is({finished}).is({checked})()}>
				{answer.answer}
			</div>
		</div>
	)
}
