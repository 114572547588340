import {RoomModel} from 'models/RoomModel'
import {fromModule} from 'util/styler/Styler'
import {Button} from './Button'
import {Container} from './Container'
import css from './Room.module.scss'

const styles = fromModule(css)

export const Room: React.FC<{
	room: RoomModel
	onStart: () => void
}> = ({room, onStart}) => {
	return (
		<Container mod="small">
			<div className={styles.room()}>
				<h2>{room.theme}</h2>
				<div className={styles.room.description()}>{room.description}</div>
			</div>
			<Button onClick={onStart} value="Start deze reeks" />
		</Container>
	)
}
