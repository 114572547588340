import {Memoize} from 'typescript-memoize'
import {QuestionModel} from './QuestionModel'

export type AnswerData = {
	answer?: string
	image?: string
	correct: boolean
}

export class AnswerModel {
	constructor(readonly question: QuestionModel, readonly state: AnswerData) {}

	@Memoize()
	get index() {
		return this.question.answers.findIndex(a => a === this)
	}

	@Memoize()
	get answer() {
		return this.state.answer
	}

	@Memoize()
	get image() {
		return this.state.image
	}

	@Memoize()
	get isCorrect() {
		return this.state.correct
	}
}
